import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import Sservice from '../components/Service/Sservice/Sservice';
import AgencyAbout from '../components/About/AgencyAbout';
import Partner from '../components/Partner';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterThree from '../components/Footer/FooterThree';
import ServiceData from '../components/Service/ServiceData';
import FooterData from '../components/Footer/FooterData';
import AboutDetail from '../components/About/AboutDetail';

const About = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar mClass="menu_four hosting_menu" nClass="w_menu" slogo="sticky_logo"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="About Us" Pdescription="Why I say old chap that is spiffing off his nut arse pear shaped plastered Jeffrey bodge barney some dodgy.!!"/>
            <AboutDetail />
            <FooterThree FooterData={FooterData} />
            {/* <FooterTwo FooterData={FooterData}/> */}
        </div>
    )
}
export default About;