import React, { Component } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
/*------ Pages-----*/
import { Home } from "./Pages/Home";
import About from "./Pages/About";
import ServiceDetails from "./Pages/ServiceDetails";

import ScrollToTopRoute from "./ScrollToTopRoute";
import NotFound from "./Pages/404";

class App extends Component {
  componentDidMount() {
    this.props.hideLoader();
  }
  render() {
    return (
      <Router>
        <Switch>
          <ScrollToTopRoute exact={true} path={"/"} component={Home} />
          <ScrollToTopRoute path="/About" component={About} />
          <ScrollToTopRoute
              path="/service/:serviceName/:serviceImage"
              component={ServiceDetails}
                        
          />
          {/* <ScrollToTopRoute path = "/about" component = {AboutUs} /> */}
          <ScrollToTopRoute component={NotFound} />
        </Switch>
      </Router>
    );
  }
}

export default App;
