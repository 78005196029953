import React from 'react';
import {Link, NavLink , useHistory, useLocation } from 'react-router-dom';
import SeoTitle from '../Title/SeoTitle';
import Fade from 'react-reveal/Fade';

const AboutUs = () => {

    const loc = useLocation();
    const history = useHistory();

    const navigateHome = () => {
        history.push("/");
    };

    return(
        <React.Fragment>
            <section className="seo_features_one sec_pad" >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 d-flex align-items-center">
                            <Fade bottom cascade>
                                <div className="seo_features_content">
                                    <h2 className="wow fadeInUp"> Seamales Sea Shipping: Navigating Global Trade with Ease</h2>
                                    
                                    <p className="wow fadeInUp">Modern Sea Shipping is a leading player in the global maritime industry, with extensive experience in providing efficient, reliable, and eco-friendly sea freight services.</p>
                                    <a 
                                        href={
                                            loc.pathname === "/"
                                                ? "#services"
                                                : "/#services"
                                        } 
                                        onClick={navigateHome} className="seo_btn seo_btn_one btn_hover wow fadeInUp">services
                                    </a>
                                    {/* <a href=".#" className="seo_btn seo_btn_one btn_hover wow fadeInUp">About Us</a> */}
                                </div>
                            </Fade>
                        </div>
                        <div className="col-lg-6">
                            <div className="seo_features_img seo_features_img_two">
                                <div className="round_circle"></div>
                                <div className="round_circle two"></div>
                                <img src={require('../../img/seo/aboutUs.png')} alt=""/>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
            <section className="seo_features_one sec_pad" id='About-us'>
                <div className="container">
                    <div className="row flex-row-reverse">
                        
                        <div className="col-lg-6">
                            <Fade bottom cascade>
                                <div className='tagline'>
                                    <div className='red-line'></div>
                                    <p> About Us</p>

                                </div>
                                <div className="seo_features_content">
                                    <h2>Trusted Transport Sea Shipping Company</h2>
                                    <p>Modern Sea Shipping is an Ocean Transport Intermediary that holds federal licenses and bonds. We leverage confidential contracts with carriers and establish close partnerships overseas to facilitate the movement of cargo from any location in Dubai to destinations worldwide. Our robust international network ensures that our exceptional service transcends Dubai's borders. We contribute to our customers' success by providing dependable and stress-free transportation services through personalized customer experiences and the cultivation of strong intercompany relationships.</p>
                                    <div className="media seo_features_item">
                                        <div className="icon"><img src={require('../../img/seo/about1.png')} alt=""/></div>
                                        <div className="media-body">
                                            <h3>Global Service</h3>
                                            
                                        </div>
                                        <div className="icon"><img src={require('../../img/seo/about2.png')} alt=""/></div>
                                        <div className="media-body">
                                            <h3>Eco-friendly Operation</h3>
                                        </div>
                                    </div>
                                    <div className="media seo_features_item">
                                        <div className="icon"><img src={require('../../img/seo/about3.png')} alt=""/></div>
                                        <div className="media-body">
                                            <h3>24/7 Customer Support</h3>
                                        </div>
                                        <div className="icon"><img src={require('../../img/seo/about4.png')} alt=""/></div>
                                        <div className="media-body">
                                            <h3>flexible Cargo Solution</h3>
                                        </div>
                                    </div>
                                    <Link to={`/About`} >
                                        <a href=".#" className="seo_btn seo_btn_one btn_hover wow fadeInUp">About Us</a>
                                    </Link>
                                    

                                </div>
                            </Fade>
                        </div>
                        <div className="col-lg-6">
                            <div className="seo_features_img">
                                <div className="round_circle"></div>
                                <div className="round_circle two"></div>
                                <img src={require('../../img/seo/aboutUs1.png')} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
        </React.Fragment>
    )
}

export default AboutUs;
