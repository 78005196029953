import React from 'react';
import Fade from 'react-reveal/Fade';

const AboutDetail =()=>{
    return(
        <React.Fragment>
            <section className="seo_features_one sec_pad">
                <div className="container">
                    <div className="row flex-row-reverse">
                        <div className="col-lg-6">
                            <div className="seo_features_img">
                                <div className="round_circle"></div>
                                <div className="round_circle two"></div>
                                <img src={require('../../img/seo/1.jpg')} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <Fade bottom cascade>
                                <div className="seo_features_content">
                                    <h2>More About US</h2>
                                    <p>Modern Sea Shipping stands out as the ideal residence in the realm of logistics and shipping. Feel free to step in anytime and utilize our services for air, sea, inland, and even challenging areas such as defense and war zones.</p>
                                    <p>Situated in the thriving hub of Dubai, our seasoned professionals, with two decades of industry expertise, form a cohesive and skilled team.</p>
                                    <p>As a frontrunner in freight forwarding services, MSS has been making history, leaving a lasting mark through exceptional customer service and efficient supply chain logistics. Our collaboration with third-party logistics aims to identify and bridge gaps in your supply chain, fostering a more agile and responsible transactional partnership.</p>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>
            <section className="seo_features_one sec_pad">
                <div className="container">
                    <div className="row flex-row-reverse">
                        
                        <div className="col-lg-6">
                            <Fade bottom cascade>
                                <div className="seo_features_content">
                                    <h2>WHAT WE DO?</h2>
                                    <p>Consider the premier choice for shipping and freight management solutions in Dubai, where excellence is defined by innovative and advanced logistics services tailored to customer needs – that's where WE EXCEL!</p>
                                    <p>Whatever your requirements, we're there for you every step of the way!</p>
                                </div>
                            </Fade>
                        </div>
                        <div className="col-lg-6">
                            <div className="seo_features_img seo_features_img_two">
                                <div className="round_circle"></div>
                                <div className="round_circle two"></div>
                                <img src={require('../../img/seo/WHAT WE DO_.jpg')} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="seo_features_one sec_pad">
                <div className="container">
                    <div className="row flex-row-reverse">
                        <div className="col-lg-6">
                            <div className="seo_features_img">
                                <div className="round_circle"></div>
                                <div className="round_circle two"></div>
                                <img src={require('../../img/seo/FLY WITH US.jpg')} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <Fade bottom cascade>
                                <div className="seo_features_content">
                                    <h2>FLY WITH US</h2>
                                    <p>Offering a variety of storage and shipping solutions, we facilitate the export and import of goods through aircraft chartering. Our global partnerships enable seamless door-to-door services.</p>
                                    
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>
            <section className="seo_features_one sec_pad">
                <div className="container">
                    <div className="row flex-row-reverse">
                        
                        <div className="col-lg-6">
                            <Fade bottom cascade>
                                <div className="seo_features_content">
                                    <h2>SHIP WITH US</h2>
                                    <p>We aid you in finding cost-effective solutions for transporting both LCL and FCL loads, partnering with top feeder operators and mainline container carriers.</p>
                                    
                                </div>
                            </Fade>
                        </div>
                        <div className="col-lg-6">
                            <div className="seo_features_img seo_features_img_two">
                                <div className="round_circle"></div>
                                <div className="round_circle two"></div>
                                <img src={require('../../img/seo/SHIP WITH US.jpg')} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="seo_features_one sec_pad">
                <div className="container">
                    <div className="row flex-row-reverse">
                        <div className="col-lg-6">
                            <div className="seo_features_img">
                                <div className="round_circle"></div>
                                <div className="round_circle two"></div>
                                <img src={require('../../img/seo/STORE WITH US.jpg')} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <Fade bottom cascade>
                                <div className="seo_features_content">
                                    <h2>STORE WITH US</h2>
                                    <p>A meticulously constructed warehouse management system that prioritizes security, providing comprehensive packing and storage through the utilization of advanced technology and skilled labor.</p>
                                    
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="seo_features_one sec_pad">
                <div className="container">
                    <div className="row flex-row-reverse">
                        
                        <div className="col-lg-6">
                            <Fade bottom cascade>
                                <div className="seo_features_content">
                                    <h2>TRANSPORT WITH US</h2>
                                    <p>Hoisting the banners of success in crucial defense and warzone sectors through the transportation of cargo, contributing to the reconstruction of industrial commerce and communities.</p>
                                    
                                </div>
                            </Fade>
                        </div>
                        <div className="col-lg-6">
                            <div className="seo_features_img seo_features_img_two">
                                <div className="round_circle"></div>
                                <div className="round_circle two"></div>
                                <img src={require('../../img/seo/TRANSPORT WITH US.jpg')} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <section className="seo_features_one sec_pad">
                <div className="container">
                    <div className="row flex-row-reverse">
                        <div className="col-lg-6">
                            <div className="seo_features_img">
                                <div className="round_circle"></div>
                                <div className="round_circle two"></div>
                                <img src={require('../../img/seo/REDESIGN WITH US.jpg')} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <Fade bottom cascade>
                                <div className="seo_features_content">
                                    <h2>REDESIGN WITH US</h2>
                                    <p>renovate your perishable products using inventive approaches to minimize overall system costs and improve astute asset management, thus refining and optimizing your shipping experience.</p>
                                    
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
        
        
    )
} 
export default AboutDetail;