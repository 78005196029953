import React from 'react';

const ServiceDetails =({title})=> {

    let text= "";
    let newTitle = title.split("-").join(" ");

    let firstTitle = "" ;
    let secondTitle = " ";
    let thirdTitle = " ";
    let description = "";
    let advantages = "";
    let service_detail = [];
    if(newTitle == "OCEAN FREIGHT"){
        firstTitle = "SAILING FOR YOU" ;
        secondTitle = "ADVANTAGES" ;
        thirdTitle = "Our value-added services include:"
        description = " MSS Shipping and Logistics offer an outstanding service experience in ocean freight, covering both Full Container Load (FCL) and Less than Container Load (LCL) services. Our focus is on delivering highly personalized and flexible ocean freight services worldwide. We are recognized for providing excellent value, offering our top-notch services at competitive and reasonable rates. Utilizing cost-effective and optimal methods, we prioritize efficiency and safety in our technologically advanced freight services." ;
        advantages = "Ocean freight stands out as the globally preferred method for shipping goods, given its unparalleled efficiency and cost-effectiveness. This combination of cost efficiency and effectiveness is attributed to the fact that a significant majority of global freight transportation occurs via sea routes." ;
        service_detail = ["Its low freight benefits override its rather show shipping procedures." , "Competitive market advantage for your products as the overall cost reduces." , "Flexible solution for inland haulages." ,"High advanced tracking system for scheduling arrivals and departures using reputed carriers." , "Containerized ocean freight for the less than container load (LCL) and full container loads (FCL)." , "Complete clearing and forwarding services using our in – house clearance." , "Large quantities in bulk can be exported at competitive prices"]
    }
    else if(newTitle == "AIR FREIGHT"){
        firstTitle = "AIR CRAFTING YOUR NEEDS" ;
        secondTitle = "ADVANTAGES" ;
        thirdTitle = "Our value-added services include:"
        description= " Do you require swift delivery of your goods? We provide expedited shipping solutions globally, ensuring the secure and prompt delivery of your high-value shipments. Through partnerships with airlines worldwide and comprehensive door-to-door services, we charter aircraft to meet urgent shipment needs.MSS Shipping & Logistics, a leading exporter and importer, specializes in delivering air freight services to customers worldwide. Our experienced team and collaborative efforts with airlines guarantee the fulfillment of your specific requirements, ensuring proper handling and the safety of your cargo. We offer customized packages at attractive and affordable rates, prioritizing service quality without compromise. With a history of dependable service spanning several years, MSS is renowned for trustworthiness and reliability." ;
        advantages = "Our team of experts employs strategic planning tailored to your needs, ensuring the formulation of a high-value service. When you have proficient experts overseeing your freight requirements, airfreight emerges as the optimal and straightforward shipping mode. We simplify every phase of the process and provide a tracking facility, allowing you to monitor your shipment's progress from any location worldwide through our website. Our seamless airfreight solution includes tracking from carrier booking to doorstep delivery, offering you a hassle-free experience." ;
        service_detail = ["Fastest mode of shipping" , "Global reach and coverage" , "Secure cargo transport" , "Reliability of services" , " Excellent packaging service, especially for dangerous and perishable goods" , "Transparent door to door service and 24 / 7 / 365 days service" , "Ample solutions for rightly fitting your needs into the customized schedule" , "In – time delivery and Next Flight Out service" , " Quality and delivery method is highly based on measured and result oriented reliability trend analysis" , "End to end real-time visibility and advanced technology tracking system"]
    }
    else if(newTitle == "INLAND FREIGHT"){
        firstTitle = "TRAVERSE WITH MSS" ;
        secondTitle = "ADVANTAGES" ;
        thirdTitle = "Our value-added services include:"
        description= "Are you uncertain about who to trust for the safe and timely shipping of your products at a reasonable cost? Are you seeking flexible and agile transportation for your consignments, whether it's to a short distance, remote location, or across the world? If so, you've come to the right place! We facilitate secure and dependable transportation of both Less Than Truckload (LTL) and Full Truckload (FTL) loads globally. With professional planning, consistently worry-free service, on-time deliveries, and a robust sustainability system, MSS is recognized as a trailblazer in Inland Freight. Our advantage lies in our alliance with a multitude of fleet providers, offering vehicles of all sizes and trailers of various types. Additionally, we provide the convenience of tracking your valuable consignments, recognizing the importance of keeping customers informed about shipping status. Our integrated tracking solution allows you to monitor the status of your land freight shipment directly from our website." ;
        advantages = " Our team of experts devises optimal plans to transport your consignments to challenging or remote locations seamlessly, ensuring hassle-free delivery." ;
        service_detail = ["Shipping multiple LTL (Less than Truckload) shipments together", "thereby developing an economically sensible mode of travel." , "To save money on larger shipments, lower the risk of damage, and ship products faster, we provide the best service of FTL (Full Truckload) shipping" , "Experienced in handling highly perishable and dangerous goods cargo" , "Delivering your products to GCC and worldwide" , "Excellent hauling service of Over Length and Over Weight Loads" , "Transports heavy machinery with extreme diligence and care" , "Highly flexible and on time arrangement of cranes" , "Temperature controlled transportation" , "Door to Door Delivery." , "Local land transportation." , "Highly competitive pricing." , "Real time updates & advanced tracking solution" ,"Insured high value/volume cargo." , "Bulk and irregular cargoes are accepted."," Dubai customs clearance" , "Sharjah customs clearance", "local transportation to all over UAE"]
    }
    else if(newTitle == "DEFENSE AND WAR ZONE CARGO MOVEMENTS"){
        firstTitle = "PEACEKEEPING AND DEFENCE WITH MSS" ;
        secondTitle = "WHY CHOOSE US?" ;
        thirdTitle = ""
        description= "Supporting peacekeepers and defenders during times of war by providing them with essential supplies is crucial for their missions. Waving the flags of peace and instilling hope in rebuilding communities, our dedicated team of experts at MSS is ready to expedite your consignments anywhere! Benefit from our extensive global network and local specialists who possess in-depth knowledge of the regulations governing import and export shipments by air, sea, and road. Our certified professionals are equipped to transport various types of dangerous and sensitive goods. We also offer secure storage and efficient document handling services. Navigating the procedures for different types of shipments may seem complex, especially for dangerous and sensitive goods, but with MSS as your partner, you can trust that everything will proceed seamlessly." ;
        advantages = " Our team of experts devises optimal plans to transport your consignments to challenging or remote locations seamlessly, ensuring hassle-free delivery." ;
        service_detail = [];
    }
    else if(newTitle == "THIRD PARTY LOGISTICS"){
        firstTitle = "REINVENTING 3PL" ;
        secondTitle = "Your way to lasting success" ;
        thirdTitle = ""
        description= "Expanding your business is a dynamic, thrilling, and occasionally demanding venture. Drawing on our experience as a growth collaborator for companies worldwide in various industries, we understand this journey well. The significance of logistics cannot be overstated for the success of any company. To ensure the flourishing of your business and its competitive edge, it's imperative to have the right logistics partner—one who can offer innovative solutions tailored to address the challenges of today's rapidly evolving market and technological landscape. At MSS, we boast a team of highly capable experts with the courage to question the existing norms and provide forward-thinking solutions." ;
        advantages = " Moving forward on the path to enduring success is driven by the belief that exceptional logistics underpin every prosperous business. This is precisely what MSS endeavors to achieve for you. Our proficient team at MSS is dedicated to assisting you in overcoming all your logistical challenges, supporting you on your journey toward success. Functioning as a responsible logistics partner, we actively engage at any stage of your company's development, standing by you from a local startup to the evolution into a global conglomerate. Recognizing the challenges inherent in establishing sustainable success, we grasp a crucial customer requirement: proactive action, agility, and a commitment to consistently provide value-driven solutions are essential for growth. This is precisely what you can anticipate from MSS as your business's growth partner." ;
        service_detail = [];
    }
    else if(newTitle == "WAREHOUSING"){
        firstTitle = "REENVISIONED WITH SIMPLICITY" ;
        secondTitle = "" ;
        thirdTitle = "Our value-added services include"
        description= "Your business is incomplete without proper warehousing! Warehousing, along with transportation, forms a crucial component of an efficient logistics system that caters to your company's growth requirements. Your concerns are our top priorities. To meet and exceed customer expectations, we have established warehouse and storage facilities in both mainland and free zone areas. Choose MSS as your reliable partner for a professional team that ensures the careful handling and storage of your valuable products. Our state-of-the-art warehouse facilities are equipped with the latest tools for optimal handling and storage of your commodities. We provide cutting-edge warehousing solutions that significantly enhance the logistics aspect of your business. It is important to mention that all our warehouse facilities have excellent connectivity for land, air, and freight transport." ;
        advantages = "" ;
        service_detail = ["Tailored solutions crafted to surpass the expectations of contemporary manufacturers, retailers, and remote resellers, allowing them to concentrate on their primary business activities.","Flexible, reliable and integrated e-fulfilment services for stress free inventory control.","Secure warehouse facilities for cost effective import and export of goods.","Comprehensive handling services created to guarantee the safe and efficient loading and unloading of your cargo.","Complete logistic assistance tailored to your needs; our state-of-the-art facilities and versatile fleet of vehicles provide comprehensive logistics, distribution, and storage support for your business.","Our advanced inventory management system provides complete and effective traceability, tracking, real-time updates, and easily comprehensible reporting to ensure efficient stock flow and control.","Best configuration for maximum storage capacity, fast and easy access to every pallet and precise order picking straight from racking.","All warehouses are strategically positioned in close proximity to outstanding transportation links for air, land, and sea freight.","Provides increased flexibility to facilitate prompt deliveries to clients with quick turnaround requirements.", "Can re-export easily.","MSS handles all customs documentation.","Short and long-term storage","Flexible terms and storage space","Distribution of consignments and cargo.","On-demand pick and pack service."];
    }
    else if(newTitle == "HUMANITARIAN AID CARGO MOVEMENT"){
        firstTitle = "AID AND RELIEF WITH MSS" ;
        secondTitle = "WHY CHOOSE US?" ;
        thirdTitle = ""
        description= "Extending support to humanitarian organisations to help extending their hands in the hour of need through emergency and critical situations with our professional team’s rapid action plan effective and strategized for low-risk shipping to every location around the globe. Supporting every government, international and national NGOs and aid organizers to transport their largescale support freights. With unparalleled experience in navigations and peregrinations throughout harsh terrains and rough weather, most remote, disaster, medical emergency destinations extreme care and diligence MSS have proved to be best in the industry. Known for our rapid action plan and travel ventures around the globe in critical situations with great ease. Emergency situations often pose the greatest logistic challenge, but our experts with their experience of transporting freights to such areas specifically formulates a strategic plan for each shipment to ensure the delivery of goods in time. These freights on time arrival are vital for the smooth running of relief operations, that is why we ensure to provide our 24/7/365 response team to deliver these freights." ;
        advantages = "Our rapid support team is available 24/7/365, diligently locating the nearest and fastest Estimated Time of Arrival (ETA) freight transport options for your cargo. They assess and formulate risk elimination strategies based on their expertise, having undergone intensive training to comprehensively manage such freights from initiation to completion. Supported by a robust on-the-ground local network of efficient associates worldwide, we gather analytics and ensure the smooth delivery of each freight to its respective destination. Our extensive global coverage ensures that, regardless of the origin or destination of your cargo, our international network provides local expertise on a global scale. Our committed managers go the extra mile to facilitate and tailor freight deliveries, ensuring a hassle-free experience with guaranteed safety. We offer global transportation services for air, sea, and land freights. Contact us now to learn more!" ;
        service_detail = [];
    }
    return(
        <section className="service_details_area sec_pad">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 pr_70">
                        <div className="job_info">
                        <div className="details_content">
                            <div className="sec_title">
                                {thirdTitle !== "" && (
                                    <h6 className="f_500 f_size_20">
                                        {thirdTitle}
                                    </h6>
                                ) }
                                {service_detail.length > 0  && (
                                    <div>
                                        {service_detail.map((detail, index) => (
                                        <p key={index} className="f_400 f_size_15" style={{ marginTop: '10px'}}>
                                            <b>{index + 1}.</b> {detail}
                                        </p>
                                    ))}
                                    </div>
                                    
                                )}
                                
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="details_content">
                            <div className="sec_title">
                                {firstTitle !== "" && (
                                    <h2>
                                        {firstTitle}
                                    </h2>
                                ) }
                                {description !== "" && (
                                    <p
                                        className="f_400 f_size_15"
                                        
                                    > {description}</p>
                                )}
                                {secondTitle !== "" && (
                                    <h3>
                                        {secondTitle}
                                    </h3>
                                ) }
                                {advantages !== "" && (
                                    <p
                                        className="f_400 f_size_15"
                                        
                                    > {advantages}</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default ServiceDetails;