import React, {Component,useState, useContext} from 'react';
import {Link, NavLink , useHistory, useLocation } from 'react-router-dom';
import Sticky from 'react-stickynode';
// import {LocationContext} from '../Pages/store/LocationContext' ;

function CustomNavbar({ mClass, nClass, cClass, slogo, hbtnClass }) {

        // const { location, addLocation } = useContext(LocationContext);
        const [isMenuOpen, setMenuOpen] = useState(false);

        const toggleMenu = () => {
            setMenuOpen(!isMenuOpen);
        };

        const closeMenu = () => {
            setMenuOpen(false);
        };
        const loc = useLocation();
        const history = useHistory();

        const navigateHome = () => {
            history.push("/");
        };

        return (
            <div>
                
                <div className="toolbar-area">
                    <div className="container">
                        <div className="row">
                            {/* <div className="col-lg-12 col-md-6 col-12">
                                
                            </div> */}
                            <div className="col-lg-12 col-md-6 col-12">
                                <div className="toolbar-social">
                                    <div className='button'>
                                        <span><i className="ti-email icon"></i><a href="mailto:info@modernseashipping.com" style={{ color: 'white' }}> info@modernseashipping.com</a></span>
                                        <span><i className="ti-location-pin icon"></i> Office: Al Khabeesi Building P.O. Box: 33624, Deira - Dubai</span>
                                        <span><i className="ti-mobile icon"></i> <a href="tel: 00971553445759" style={{ color: 'white' }}> 00971 55344 5759 </a></span>
                                    </div>
                                    {/* <ul>
                                        <li><span className="title">Follow Us On : </span></li>
                                        <li><i className="ti-email"></i> <a href='#'>mss@gmail.com</a></li>
                                        <li><i className="ti-email"></i> <a href='#'>mss@gmail.com</a></li>
                                        <li><i className="ti-email"></i> <a href='#'>mss@gmail.com</a></li>
                                        
                                    </ul> */}
                                </div>
                            </div>
                            
                            
                            
                        </div>
                    </div>
                </div>

                <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
                    <header className="header_area">
                        <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
                            <div className={`container ${cClass}`}>
                                <Link className={`navbar-brand ${slogo}`} to="/">
                                    <img
                                        src={require("../img/Mss/MSS LOGO-white.png")}
                                        alt="logo"
                                        width="100"
                                        height="50"
                                    />
                                    <img
                                        src={require("../img/Mss/logo.png")}
                                        alt="logo"
                                        width="100"
                                        height="100"
                                        id="site-logo"
                                    />
                                    {/* <img src={require("../img/Mss/MSS Logo-100.jpg")} alt=""/> */}
                                    {/* <img src={require("../img/Mss/MSS Logo-100.jpg")} alt="logo"/> */}
                                </Link>
                                <button className={`navbar-toggler collapsed ${isMenuOpen ? 'active' : ''}`}
                                 type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded={isMenuOpen ? 'true' : 'false'} onClick={toggleMenu} aria-label="Toggle navigation" >
                                    <span className="menu_toggle">
                                        <span className="hamburger">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </span>
                                        <span className="hamburger-cross">
                                            <span></span>
                                            <span></span>
                                        </span>
                                    </span>
                                </button>

                                <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarSupportedContent">
                                    <ul className={`navbar-nav menu ml-auto ${nClass}`}>
                                        <li className="nav-item">
                                            <Link to="/" className="nav-link" role="button" onClick={() => { window.scrollTo(0, 0); closeMenu(); }}>
                                                Home
                                            </Link>
                                            
                                        </li>
                                        <li className="nav-item" onClick={() => { navigateHome(); closeMenu(); }}>
                                            {/* <Link to="#process" className="nav-link">
                                                Process
                                            </Link> */}

                                            <a
                                                href={
                                                    loc.pathname === "/"
                                                        ? "#About-us"
                                                        : "/#About-us"
                                                }
                                                className="nav-link"
                                            >
                                                About Us
                                            </a>
                                        </li>
                                        <li className="nav-item" onClick={() => { navigateHome(); closeMenu(); }}>
                                            {/* <Link to="#process" className="nav-link">
                                                Process
                                            </Link> */}

                                            <a
                                                href={
                                                    loc.pathname === "/"
                                                        ? "#services"
                                                        : "/#services"
                                                }
                                                className="nav-link"
                                            >
                                                Services
                                            </a>
                                        </li>

                                        <li className="nav-item" onClick={() => { navigateHome(); closeMenu(); }}>
                                            {/* <Link to="#process" className="nav-link">
                                                Process
                                            </Link> */}

                                            <a
                                                href={
                                                    loc.pathname === "/"
                                                        ? "#contact_us"
                                                        : "/#contact_us"
                                                }
                                                className="nav-link"
                                            >
                                                Contact Us
                                            </a>
                                        </li>
                                    </ul>
                                    <a className={`btn_get btn_hover ${hbtnClass}`} onClick={() => { navigateHome(); closeMenu(); }}
                                     href={
                                        loc.pathname === "/"
                                            ? "#contact_us"
                                            : "/#contact_us"
                                        }>
                                        Get a Quote</a>
                                    
                                </div>
                            </div>
                        </nav>
                    </header>
                </Sticky>
            </div>
            
            
        );
    
}

export default CustomNavbar;