import React, {Component} from 'react';
import StudySlider from '../StudySlider';
import SeoTitle from '../Title/SeoTitle';
import Testimonial from '../Testimonial/Testimonial';
import Fade from 'react-reveal/Fade'

class Services extends Component {
    render(){
        let servicedata = this.props.ServiceData;
        const mystyle ={
            backgroundColor: '#F5FAFF' ,
        };
        const mystyle1 = {
            padding: '10px',
        };
        return(
            <React.Fragment>
                
                <section className="case_studies_area sec_pad" id='services'>
                    <div className="container">
                        <div className='row' style={mystyle}>
                            <div className='col-lg-4' style={mystyle1}>
                                <div className='tagline'>
                                    <div className='red-line'></div>
                                    <p> What We Do</p>

                                </div>
                                <p className='description'>  We are optimists who Love to work together. </p>
                                {/* <SeoTitle Title="What We Do" TitleP="We are optimists who Love to work together."/> */}
                            </div>
                            <div className='col-lg-8'>
                                <StudySlider servicedata = {servicedata} />
                            </div>
                    </div>
                        
                        
                        
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

export default Services;