import React, {Component} from 'react';
import {Link} from 'react-router-dom';

class FooterThree extends Component{
    render(){
        let FooterData = this.props.FooterData;
        return(
            <footer className="footer_nine_area">
                <div className="footer_nine_top">
                    <div className="footer_shap left"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 col-md-6">
                                <div className="f_widget company_widget pr_100">
                                    <a href="index.html" className="f-logo"><img
                                                        src={require("../../img/Mss/MSS LOGO-white.png")}
                                                        alt="logo"
                                                        width="150"
                                                        height="75"
                                                        /></a>
                                    <p className="f_400 f_p f_size_16 mb-0 l_height28 mt_40">MSS provides customers with dependable, secure, and high-performing solutions for industrial crane components and drives.</p>
                                    <div className="widget-wrap">
                                        <p className="f_400 f_p f_size_15 mb-0 l_height34"><i className='ti-email'> </i> <a href="info@modernseashipping.com" className="f_400">info@modernseashipping.com</a></p>
                                        <p className="f_400 f_p f_size_15 mb-0 l_height34"><i className='ti-email'> </i> <a href="mailto:Muhadraza786@gmail.com" className="f_400">Muhadraza786@gmail.com</a></p>
                                        <p className="f_400 f_p f_size_15 mb-0 l_height34"><i className='ti-comment-alt'></i> <a href="https://wa.me/971553445759" className="f_400">+971 55 344 57 59</a></p>
                                        <p className="f_400 f_p f_size_15 mb-0 l_height34"><i className='ti-mobile'></i> <a href="tel:+971509344522" className="f_400">+971 50 934 45 22</a></p>
                                        
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-lg-2 col-md-6">
                                <div className="f_widget about-widget">
                                    <h3 className="f-title f_500 f_size_16 mb-30">About Us</h3>
                                    <ul className="list-unstyled f_list">
                                        {
                                            FooterData.aboutLinks.map(links => {
                                                return(
                                                    <li key={links.id}><Link to={links.url}>{links.item}</Link></li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div> */}
                            <div className="col-lg-3 col-md-6">
                                <div className="f_widget about-widget">
                                    <h3 className="f-title f_500 f_size_16 mb-30">Help & Suport</h3>
                                    <ul className="list-unstyled f_list">
                                        {
                                            FooterData.helpLinks.map(links => {
                                                return(
                                                    <li key={links.id}><Link to={links.url}>{links.item}</Link></li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="f_widget about-widget">
                                    <h3 className="f-title f_500 f_size_16 mb-30">Privacy Contact</h3>
                                    <ul className="list-unstyled f_list">
                                        {
                                            FooterData.PrivacyLinks.map(links => {
                                                return(
                                                    <li key={links.id}><Link to={links.url}>{links.item}</Link></li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer_nine_bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-7 align-self-center">
                                <p className="mb-0 f_400">© Modern Sea Shipping 2023. All Rights Reserved</p>
                            </div>
                            <div className="col-sm-5">
                                <p className="mb-0 f_400">Designed & Developed By <a href='https://eparameter.com/'><b>eParameter</b></a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}
export default FooterThree;