const WebsiteData = {
    AboutUs : [
        {
            id: 1,
            title : 'Empowering Global Trade Seamales Sea Shipping Solution',
            description : 'Modern Sea Shipping Solutions, established in 1998, stands as a beacon in the global maritime industry. With over two decades of unparalleled experience, we specialize in offering efficient, reliable, and eco-friendly sea freight services.',
        },
        {
            id: 2,
            title : 'Digital & Trusted Transport Sea Shipping Company',
            Shortdescription : 'Our expansive fleet, complemented by a passionate team, has successfully navigated the worlds major shipping lanes, fostering global trade and delivering promises. At Modern Sea Shipping, we do not just ship goods; we build lasting relationships, one voyage at a time.',
            Longdescription : 'Our expansive fleet, complemented by a passionate team, has successfully navigated the worlds major shipping lanes, fostering global trade and delivering promises. At Modern Sea Shipping, we do not just ship goods; we build lasting relationships, one voyage at a time.',
        }
    ],
    ClientsReview: [
        {
            id : 1,
            title: 'Timely Delivered',
            image : 'client',
            description : 'I am thoroughly impressed with the modern sea shipping services. From efficient handling to timely deliveries, their commitment to safety and reliability truly sets them apart. A trustworthy partner for seamless global shipping.',
            Name : 'Abdullah',
        },
        {
            id : 2,
            title: 'International Shipment',
            image : 'client',
            description : 'I recently had the pleasure of using Modern Sea Shipping for an international shipment, and I must say, the experience exceeded my expectations. From start to finish, the entire process was seamless and stress-free.',
            Name : 'Irfan',
        },
        {
            id : 3,
            title: 'Real Time Tracking',
            image : 'client',
            description : 'The efficiency of the shipping process was remarkable. The cargo was picked up on time, and I was kept informed about its journey at every step. Real-time tracking updates were accurate and helpful, providing peace of mind throughout the transit period. Modern Sea Shippings commitment to transparency is commendable.',
            Name : 'Jahangir',
        },
        {
            id : 4,
            title: 'Customer Servicer',
            image : 'client',
            description : 'Customer service played a crucial role in making my experience positive. The support team was responsive, addressing my queries promptly and professionally. Its evident that customer satisfaction is a priority for Modern Sea Shipping, and their commitment to excellence sets them apart from other shipping providers.',
            Name : 'Raziq',
        },
        
    ],
    Services: [
        {
            id: 1,
            image: 'OCEAN-FREIGHT',
            title: 'OCEAN-FREIGHT',
            subTitle: 'VOYAGE-WITH-MSS',
            description: 'Offering the optimal solution for transporting both Less than Container Load (LCL) and Full Container Load (FCL) shipments at a cost-effective rate without compromising on quality. Utilizing top-tier feeder operators and mainline carriers in the industry. ',
        },
        {
            id: 2,
            image: 'AIR-FREIGHT',
            title: 'AIR-FREIGHT',
            subTitle: 'EXPEDITE-WITH-MSS',
            description: 'Providing swift global shipping solutions with no risk, ensuring the timely delivery of your high-value shipments. We charter aircraft through global partnerships and offer door-to-door services for a seamless experience.',
        },
        {
            id: 3,
            image: 'INLAND-FREIGHT',
            title: 'INLAND-FREIGHT',
            subTitle: 'TRAVERSE-WITH-MSS',
            description: 'Are you facing uncertainty about choosing a trustworthy partner for secure and timely product delivery at a reasonable cost? Whether you require flexible and agile consignment transport or need to reach a nearby or remote destination, your search ends here! We specialize in providing the most dependable and secure transportation for both Less than Truckload (LTL) and Full Truckload (FTL) shipments across Asia. ',
        },
        
        {
            id: 4,
            image: 'THIRD-PARTY-LOGISTICS',
            title: 'THIRD-PARTY-LOGISTICS',
            subTitle: 'REINVENTING-3PL',
            description: 'To ensure the success and competitive edge of your business, you require the correct logistics partner—one capable of delivering innovative solutions tailored to meet the demands of todays rapidly evolving market and technological environment. At MSS, we boast a team of highly capable experts with the audacity to challenge the status quo, paving the way for the prosperity of your business. ',
        },
        {
            id: 5,
            image: 'WAREHOUSING',
            title: 'WAREHOUSING',
            subTitle : 'STORE-WITH-MSS',
            description: 'A key element missing in your business is a warehouse! MSS stands out as the ideal partner for those seeking a reliable professional team to manage and store their goods with the highest level of care. Our cutting-edge warehouse facility is equipped with the latest and most advanced tools, guaranteeing optimal handling and storage of your goods.',
        },
        {
            id: 6,
            image: 'HUMANITARIAN-AID-CARGO-MOVEMENT',
            title: 'HUMANITARIAN-AID-CARGO-MOVEMENT',
            subTitle : 'AID-AND-RELIEF-WITH-MSS',
            description: 'Offering assistance to humanitarian organizations to extend their aid during emergencies and critical situations, our professional team implements a rapid action plan that is both effective and strategically designed for low-risk shipping to any destination worldwide.',
        },
    ]
}
export default WebsiteData;