import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import ServiceDetails from '../components/Service/ServiceDetails';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterThree from '../components/Footer/FooterThree';
import FooterData from '../components/Footer/FooterData';

const Faq = ({match}) => {
    let title = "";
    let metaDescription = " ";
    if(match.params.serviceName == "OCEAN-FREIGHT"){
        title= " OCEAN FREIGHT" ;
    }
    else if(match.params.serviceName == "AIR-FREIGHT"){
        title = " AIR FREIGHT"
    }
    else if(match.params.serviceName == "INLAND-FREIGHT"){
        title = " INLAND FREIGHT"
    }
    else if(match.params.serviceName == "DEFENSE-AND-WAR-ZONE-CARGO-MOVEMENTS"){
        title = " DEFENSE AND WAR ZONE CARGO MOVEMENTS"
    }
    else if(match.params.serviceName == "THIRD-PARTY-LOGISTICS"){
        title = " THIRD PARTY LOGISTICS"
    }
    else if(match.params.serviceName == "WAREHOUSING"){
        title = " WAREHOUSING"
    }
    else if(match.params.serviceName == "HUMANITARIAN-AID-CARGO-MOVEMENT"){
        title = "HUMANITARIAN AID CARGO MOVEMENT"
    }
    return(
        <div className="body_wrapper">
            <CustomNavbar mClass="menu_four hosting_menu" nClass="w_menu" slogo="sticky_logo"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" 
                imgName="breadcrumb/banner_bg.png"
                // Ptitle="Services Details"
                Pdescription="Why I say old chap that is spiffing off his nut arse pear shaped plastered Jeffrey bodge barney some dodgy.!!"
                Ptitle={match.params.serviceName}
                icon={match.params.serviceImage}
            />
            <ServiceDetails  title = {match.params.serviceName}/>
            <FooterThree FooterData={FooterData} />
            {/* <FooterTwo FooterData={FooterData}/> */}
        </div>
    )
}
export default Faq;