import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick/';

class StudySlider extends Component {
    
    render() {
        let data = this.props.servicedata;
        // console.log(data.Services);
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 2000,
            slidesToShow: 2,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                  }
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]
          };
        return (
            <React.Fragment>
                <Slider {...settings} className="case_studies_slider">
                    {data.Services.map((item) => {
                        return(

                            

                            <Link 
                                to={`/service/${item.title}/${item.image}`} 
                                
                            
                            >
                              <div className="iitem">
                                
                                  <div className="studies_item">
                                      <img src={require("../img/seo/"+ item.image+".jpg")} alt=""/>
                                      <div className="text">
                                          <a href=".#">
                                              <h4>{item.title.split("-").join(" ")}</h4>
                                          </a>
                                          <h6>"{item.subTitle.split("-").join(" ")}"</h6>
                                          <p>{item.description}</p>
                                      </div>
                                  </div>
                                
                              </div>
                            </Link>
                            
                        );
                        
                    })}
                    
                    
                </Slider>
            </React.Fragment>
        )
    }
}

export default StudySlider;