import React from 'react';
import Fade from 'react-reveal/Fade';
const HostingBanner =() =>{
    return(
        <section className="hosting_banner_area">
            
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-7 d-flex align-items-center">
                        <div className="hosting_content">
                            {/* <h2 className="wow fadeInUp" data-wow-delay="0.3s">Modern Sea Shipping: Your Gateway to Global Trade</h2>
                            <p className="wow fadeInUp" data-wow-delay="0.5s">MSS provides customers with dependable, secure, and high-performing solutions for industrial crane components and drives.</p>
                            <a href="/#" className="hosting_btn btn_hover wow fadeInUp" data-wow-delay="0.7s">Get Started</a> */}
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-7 d-flex align-items-center">
                        <div className="hosting_content">
                            <h2 className="wow fadeInUp" data-wow-delay="0.3s">Modern Sea Shipping: Your Gateway to Global Trade</h2>
                            <p className="wow fadeInUp" data-wow-delay="0.5s">MSS provides customers with dependable, secure, and high-performing solutions for industrial crane components and drives.</p>
                            {/* <a href="/#" className="hosting_btn btn_hover wow fadeInUp" data-wow-delay="0.7s">Get Started</a> */}
                        </div>
                    </div>
                    {/* <div className="col-lg-6 col-md-5">
                        <img className="img-fluid wow fadeInRight" data-wow-delay="0.7s" src={require("../../img/hosting/hero.jpg")} alt=""/>
                    </div> */}
                </div>
            </div>
        </section>
    )
}
export default HostingBanner;