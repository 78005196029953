import React, { useContext, useRef, useState } from "react";
import Reveal from "react-reveal/Reveal/";
import emailjs from "@emailjs/browser";

const ContactSection = () => {
    const [status, setStatus] = useState(undefined);
    const form = useRef();
    const name = useRef(null);
    const email = useRef(null);
    const phone_number = useRef(null);
    // const make = useRef(null);
    const sub_urb = useRef(null);
    const message = useRef(null);
    function sendEmail(e) {
        e.preventDefault();

        emailjs
            .sendForm(
                "service_5zy8kim",
                "template_hq1asdi",
                form.current,
                "hC4uCbDiDWJv1HXN4"
            )
            .then(
                (result) => {
                    setStatus({ type: "success" });
                    name.current.value = "";
                    email.current.value = "";
                    phone_number.current.value = "";
                    // make.current.value = "";
                    sub_urb.current.value = "";
                    message.current.value = "";
                },
                (error) => {
                    setStatus({ type: "error" });
                }
            );
    }

    return (
        <section className="seo_home_area" id="contact_us">
            <div className="banner_top">
                <div className="container custom_container">
                    <div className="row">
                        <div className="col-md-12 col-lg-8 seo_banner_content mb-5">
                            <div className="saas_home_img">
                                <Reveal effect="fadeInUp" duration={1400}>
                                    {/* <img
                                        src={require("../img/Mss/ContactBackground.png")}
                                        alt=""
                                     /> */}
                                </Reveal>
                            </div>
                        </div>
                        <Reveal effect="fadeInUp" duration={1400}>
                            <div className="contact_form col-md-12 col-lg-4">
                                {/* <div className="card">
                                <div className="card-body"> */}
                                <form
                                    ref={form}
                                    className="quote_form"
                                    action="POST"
                                    onSubmit={sendEmail}
                                >
                                    <h4 className="text-center pb-3">
                                        Request A Quick Quotes
                                    </h4>
                                    {status?.type === "success" && (
                                        <p className="alert alert-success">
                                            Message Send Successfully
                                        </p>
                                    )}
                                    {status?.type === "error" && (
                                        <p className="alert alert-danger">
                                            Message Not Sent
                                        </p>
                                    )}
                                    <div className="row">
                                        {/* <div className="form-group col-12 text_box">
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="name"
                                                name="name"
                                                placeholder="Your Name"
                                                required
                                                ref={name}
                                            />
                                        </div> */}
                                        {/* <div className="col-12 text_box">
                                            <input
                                                type="email"
                                                class="form-control"
                                                id="email"
                                                name="email"
                                                placeholder="Your Email"
                                                required
                                                ref={email}
                                            />
                                        </div> */}
                                        <div className="form-group col-6 text_box">
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="name"
                                                name="name"
                                                placeholder="EnterYour Name"
                                                required
                                                ref={name}
                                            />
                                        </div>
                                        <div className="form-group col-6 text_box">
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="sub-urb"
                                                name="sub_urb"
                                                placeholder="Your Subj"
                                                required
                                                ref={sub_urb}
                                            />
                                        </div>
                                        
                                        <div className="form-group col-6 text_box">
                                            <input
                                                type="email"
                                                class="form-control"
                                                id="email"
                                                name="email"
                                                placeholder="Your Email"
                                                required
                                                ref={email}
                                            />
                                        </div>
                                        <div className="form-group col-6 text_box">
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="phone-number"
                                                name="phone_number"
                                                placeholder="EnterYour Phone"
                                                required
                                                ref={phone_number}
                                            />
                                        </div>
                                        <div className="form-group col-md-12 text_box">
                                            <textarea
                                                type="text"
                                                class="form-control message"
                                                id="message"
                                                name="message"
                                                placeholder="Your Message"
                                                required
                                                rows="1"
                                                ref={message}
                                            />
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button
                                            className="btn_three"
                                            type="submit"
                                        >
                                            Send
                                        </button>
                                    </div>
                                </form>
                                {/* </div>
                            </div> */}
                            </div>
                        </Reveal>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default ContactSection;
