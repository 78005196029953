import React from 'react';
import Fade from 'react-reveal/Fade';
import SeoTitle from './Title/SeoTitle';
import ErpanalyticsItem from "./ErpanalyticsItem";


const Unique = () => {
    return(
        <React.Fragment>
            <section className="seo_features_one sec_pad">
                <div className="container">
                    
                    <div className="row flex-row-reverse">
                        
                        
                        <div className="col-lg-6">
                            <Fade bottom cascade>
                                <div className='tagline'>
                                    <div className='red-line'></div>
                                    <p> Why Chosse Us</p>

                                </div>
                                <div className="seo_features_content">
                                    <h2>Who We are?</h2>
                                    <p>Choose Modern Sea Shipping for unparalleled expertise in global logistics, backed by our extensive network and federal licensing as an Ocean Transport Intermediary. Experience personalized service, reliable transportation, and strong international partnerships that ensure worry-free shipping solutions tailored to your needs.</p>
                                    <div className="media seo_features_item">
                                        <div className="icon2"><img src={require('../img/seo/choose1.png')} alt=""/></div>
                                        <div className="media-body">
                                            <h3 className='unqiue_header'>Fast Transport Service</h3>
                                            <p className='unqiue'>Experience swift and efficient transport services with Sea Shipping, ensuring timely delivery for your cargo.</p>
                                        </div>
                                    </div>
                                    <div className="media seo_features_item">
                                        <div className="icon2"><img src={require('../img/seo/choose2.png')} alt=""/></div>
                                        <div className="media-body">
                                            <h3 className='unqiue_header'>Safety And Reliability</h3>
                                            <p className='unqiue'>Count on Sea Shipping for the highest standards of safety and unwavering reliability in transporting your goods.</p>
                                        </div>
                                    </div>
                                    <div className="media seo_features_item">
                                        <div className="icon2"><img src={require('../img/seo/choose3.png')} alt=""/></div>
                                        <div className="media-body">
                                            <h3 className='unqiue_header'>Shipping WorildWide</h3>
                                            <p className='unqiue'>Trust Sea Shipping for seamless shipping services that connect your cargo to destinations worldwide.</p>
                                        </div>
                                    </div>
                                    
                                </div>
                            </Fade>
                        </div>
                        <div className="col-lg-6">
                            <div className="seo_features_img">
                                <div className="round_circle"></div>
                                <div className="round_circle two"></div>
                                <img src={require('../img/seo/choose_us.png')} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="erp_analytics_area">
                <div className="container">
                    {/* <Sectitle Title="Analytics" TitleP="The full monty burke posh excuse my French Richard cheeky bobby spiffing crikey Why gormless, pear shaped.!"
                    sClass="hosting_title erp_title text-center"/> */}
                    <div className="row">
                        <ErpanalyticsItem analyticsImage="costumer.png" text="Parcel Delivered"/>
                        <ErpanalyticsItem analyticsImage="delivery.png" text="Satisfied Client"/>
                        <ErpanalyticsItem analyticsImage="package.png" text="Delivered Packages"/>
                    </div>
                </div>
            </section>
            
        </React.Fragment>
    )
}

export default Unique;
