import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import AboutUs from '../components/About/About-us';
import Services from '../components/Service/Services';
import Footer from '../components/Footer/Footer';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterThree from '../components/Footer/FooterThree';
import Unique from '../components/Unique';
import TestimonialMain from '../components/Testimonial';
import ContactSection from '../components/ContactSection';
import HostingBanner from '../components/Banner/HostingBanner';
import FooterErp from "../components/Footer/FooterErp";
import FooterData from '../components/Footer/FooterData';
import { Helmet } from "react-helmet";
import WebsiteData from '../components/WebsiteData';


export const Home = () => (
    <div className="body_wrapper">
        <Helmet>
            <title>
                Modern Sea Shipping
            </title>
            <meta
                name="description"
                content="Modern Sea shippping"
            />
            <meta
                name="keywords"
                content="Modern Sea Shipping"
            />
            <link rel="canonical" href="https://modernseashipping.com/" />
        </Helmet>
        <CustomNavbar mClass="menu_four hosting_menu" nClass="w_menu" slogo="sticky_logo"/>
        <HostingBanner/>
        <AboutUs AboutData = {WebsiteData} />
        <Services ServiceData = {WebsiteData}  />
        <Unique />
        <TestimonialMain ClientReview = {WebsiteData}   />
        <ContactSection />
        <FooterThree FooterData={FooterData} />
        {/* <FooterTwo FooterData={FooterData}/> */}
    </div>
)